import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Questionnaire from "./questionnaire";
import RawData from "./raw";

const QUESTIONNAIRE = "QUESTIONNAIRE";
const RAW_DATA = "RAW_DATA";

export default () => {
  const [questionnaire, setQuestionnaire] = useState();
  const [tab, setTab] = useState(QUESTIONNAIRE);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/questionnaire/${id}`);
        setQuestionnaire(data);
      } catch (e) {
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (!questionnaire) return <Loader />;

  return (
    <div>
      <nav className="flex items-center gap-2 pl-2">
        <button
          className={`${
            tab === QUESTIONNAIRE && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(QUESTIONNAIRE)}
        >
          Questionnaire
        </button>
        <button
          className={`${
            tab === RAW_DATA && "bg-black-80 text-white"
          } transition-colors px-3 py-2 text-sm`}
          onClick={() => setTab(RAW_DATA)}
        >
          Raw Data
        </button>
      </nav>
      <div className="bg-white rounded-md p-6 border border-primary-black-50">
        {tab === QUESTIONNAIRE && (
          <Questionnaire questionnaire={questionnaire} />
        )}
        {tab === RAW_DATA && <RawData questionnaire={questionnaire} />}
      </div>
    </div>
  );
};
